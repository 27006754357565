import { QueryHookOptions, gql, useQuery } from '@apollo/client';
import { parseISO } from 'date-fns';
import { evolve } from 'ramda';
import {
  FixedIncomePositionByIdQuery,
  FixedIncomePositionByIdQueryVariables,
} from '../../../__generated__/types';

export const FIXED_INCOME_POSITION_BY_ID_QUERY = gql`
  query FixedIncomePositionById($id: UUID!) {
    fixedIncomePositionById(id: $id) {
      id
      nickname
      duration
      quantity
      referenceUnitPrice
      referenceDate
      currentPrice
      applicationDate
      maturityDate
      productKind
      averageAgio
      brokerAccount {
        id
        customerName
        accountNumber
        advisor {
          externalId
        }
      }
      createdBy
      freeOfIR
      priceApplied
      issuerId
      rebuyUnitPrice
      rebuyYieldDescription {
        indexer
        yieldRate
        indexerPercentRate
      }
      yieldDescription {
        indexer
        yieldRate
        indexerPercentRate
      }
      rebuyUnitPrice
      detailedInfoLink
    }
  }
`;

export const useFixedIncomePositionById = (
  id: string,
  options?: QueryHookOptions<
    FixedIncomePositionByIdQuery,
    FixedIncomePositionByIdQueryVariables
  >,
) => {
  const { data, loading, error } = useQuery<
    FixedIncomePositionByIdQuery,
    FixedIncomePositionByIdQueryVariables
  >(FIXED_INCOME_POSITION_BY_ID_QUERY, {
    ...options,
    variables: {
      id,
    },
  });

  const fixedIncomePosition =
    data?.fixedIncomePositionById &&
    evolve(
      {
        applicationDate: parseISO,
        maturityDate: parseISO,
      },
      data?.fixedIncomePositionById,
    );
  return {
    fixedIncomePosition: fixedIncomePosition,
    loading,
    error,
  };
};
