import { Controller, useFormContext } from 'react-hook-form';
import {
  type IPercentageInputProps,
  PercentageInput,
} from '../components/PercentageInput';
import { type BuildFormProps } from '../types/BuildFormProps';

export const FormPercentageInput: React.FC<
  BuildFormProps<IPercentageInputProps>
> = ({ name, control: controlFromProps, ...props }) => {
  const { control: controlFromContext } = useFormContext() || {};

  const control = controlFromProps || controlFromContext;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <PercentageInput
          value={field.value}
          onChange={field.onChange}
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
          {...props}
        />
      )}
    />
  );
};
